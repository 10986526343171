import { LogoWithVerticalText, Share } from "assets/svg";
import { FormattedMessage } from "react-intl";
import { TTemplate } from "./Templates.types";
import { Link } from "react-router-dom";

export default function WeddingWhite({
  bannerUrl,
  titleFontFamily,
  titleFontSize,
  titleOneText,
  titleTwoText,
  subtitleText,
  language,
  onChangeLanguage,
  onShare,
  onCapsulaLandingPageClick,
  actionButtonLabel,
  onClick,
}: TTemplate) {
  return (
    <div className="template-container flex flex-col items-center">
      <div className="px-10 mt-10 mb-8">
        <h6
          className={`${titleFontFamily} font-normal text-center -mb-0.5`}
          style={{
            fontSize: titleFontSize,
          }}
          translate="no"
        >
          {titleOneText}
        </h6>
        <div className="flex items-center justify-center gap-2">
          <div className="h-px w-24 max-w-24 flex-1 bg-theme-black"></div>
          <span className="font-playfair-display text-2xl">&</span>
          <div className="h-px w-24 max-w-24 flex-1 bg-theme-black"></div>
        </div>
        <h6
          className={`${titleFontFamily} font-normal text-center -mt-2`}
          style={{
            fontSize: titleFontSize,
          }}
          translate="no"
        >
          {titleTwoText}
        </h6>
      </div>
      <div className="px-6 w-full flex items-center justify-center">
        <div className="relative bg-white p-2 shadow-md">
          <img
            className="aspect-[1/1.2] object-cover"
            src={bannerUrl}
            alt="banner-home"
          />
        </div>
      </div>

      <div className="flex items-center my-9 mx-5">
        <p className="font-century-gothic text-sm">{subtitleText}</p>
      </div>
      <div className="flex items-center justify-center gap-4 px-5 pb-8">
        <button
          onClick={onChangeLanguage}
          type="button"
          className="shadow square-white bg-white text-theme-black p-3 h-10 w-10 flex justify-center items-center"
          data-testid="change-language"
        >
          {language}
        </button>
        <button
          onClick={onClick}
          type="button"
          className="shadow square-white bg-white font-century-gothic text-sm text-theme-black h-10 px-10 flex-auto flex whitespace-nowrap justify-center items-center"
        >
          {actionButtonLabel}
        </button>
        <button
          onClick={onShare}
          type="button"
          className="shadow square-white bg-white text-theme-black p-3 h-10 w-10 flex justify-center items-center"
        >
          <Share width={20} height={20} outline="#272727" color="#272727" />
        </button>
      </div>

      <div className="text-terms">
        <FormattedMessage
          id="message.termsOfUse"
          defaultMessage="Ao utilizar nosso sistema você concorda com nossos <code>Termos de Uso</code>"
          values={{
            code: (text) => (
              <Link to="/terms" className="underline text-terms">
                {text}
              </Link>
            ),
          }}
        />
      </div>

      <div className="flex justify-center items-center mt-5 mb-2">
        <button onClick={onCapsulaLandingPageClick} type="button">
          <LogoWithVerticalText
            width={115}
            height={17}
            color="black"
            outline="black"
          />
        </button>
      </div>
    </div>
  );
}
